// import React, { useState, useEffect } from "react";

// <style></style>;
// function Header1() {
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//   const toggleNavbar = () => {
//     setIsNavbarOpen((prevState) => !prevState);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       // Your scroll handling code here
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scroll = window.scrollY;
//       const navbarArea = document.querySelector(".navbar-area");
//       const navbarBrandImg = document.querySelector(
//         ".navbar .navbar-brand img"
//       );
//       if (scroll < 20) {
//         navbarArea.classList.remove("sticky");
//         navbarBrandImg.src = "/assets/images/logo-white.png";
//       } else {
//         navbarArea.classList.add("sticky");
//         navbarBrandImg.src = "/assets/images/logoo.png";
//       }
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <header className="header-area">
//       <div className="navbar-area">
//         <div className="container relative">
//           <div className="row">
//             <div className="w-full">
//               <nav className="flex items-center justify-between navbar navbar-expand-lg">
//                 <a className="mr-4 navbar-brand" href="/">
//                   <img src="assets/images/logo-white.png" alt="Logo" />
//                 </a>
//                 <button
//                   className="block navbar-toggler focus:outline-none lg:hidden"
//                   type="button"
//                   onClick={toggleNavbar}
//                 >
//                   <span className="toggler-icon"></span>
//                   <span className="toggler-icon"></span>
//                   <span className="toggler-icon"></span>
//                 </button>

//                 <div
//                   className={`absolute left-0 z-20 w-full px-1  duration-300 bg-black shadow-lg lg:w-auto  navbar-collapse lg:block top-full mt-full lg:static lg:bg-transparent lg:shadow-none ${
//                     isNavbarOpen ? "" : "hidden"
//                   }`}
//                   id="navbarOne"
//                 >
//                   <ul
//                     id="nav"
//                     className="items-center content-start mr-auto lg:justify-center navbar-nav lg:flex"
//                   >
//                     <li className="nav-item active">
//                       <a className="page-scroll" href="#home">
//                         Home
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#features">
//                         Universities
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#about">
//                         Colleges
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#facts">
//                         Courses
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#team">
//                         Exams
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#blog">
//                         Gallery
//                       </a>
//                     </li>
//                     <li className="nav-item">
//                       <a className="page-scroll" href="#blog">
//                         Login
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </nav>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         id="home"
//         className="header-hero"
//         style={{ backgroundImage: "url(/assets/images/slider.png)" }}
//       >
//         <div className="container">
//           <div className=" row">
//             <div className="w-full lg:w-1/2">
//               <div className="pt-8 mb-8 text-center lg:pt-48 header-hero-content">
//                 <h3 className="text-4xl font-light leading-tight text-white header-sub-title"></h3>
//                 <h2 className="mb-20  header-title">
//                   <div className="justify-center row">
//                     <div className="w-full lg:w-1">
//                       {/* <div className="text-center header-hero-image mr-5 mb-9">
//                         <img src="/assets/images/header-hero.png" alt="hero" />
//                       </div> */}
//                     </div>
//                   </div>
//                 </h2>
//                 <br></br>
//                 <br></br>
//                 <br></br>
//                 <br></br>
//                 <br></br>
//                 {/* <a href="/" className="main-btn ">

//                 </a> */}
//               </div>
//             </div>

//             <div className="w-full lg:w-1/3 ml-auto">
//               <div className="pt-8 header-hero-image">
//                 <div className=" py-2 sm:py-8 lg:py-6">
//                   <div className="mx-auto max-w-screen-sm px-4 md:px-8">
//                     <div className="mb-10 md:mb-16"></div>
//                     <div className="w-full max-w-xs">
//                       <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
//                         <h2 class="text-red-500 text-lg font-bold mb-4">
//                           APPLY NOW
//                         </h2>

//                         <p>Your next opportunity awaits!</p>

//                         <hr class="my-2" />

//                         <div class="grid grid-cols-2 gap-4">
//                           <button class="bg-white hover:bg-red-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
//                             Abroad
//                           </button>
//                           <button class="bg-white hover:bg-red-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
//                             India
//                           </button>
//                         </div>
//                         {/* <hr className="my-4 border-b border-gray-400" /> */}
//                         <h4 className="my-2">Enter Your Details</h4>

//                         <div class="mb-2">
//                           <input
//                             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                             id="name"
//                             type="text"
//                             placeholder="Your Name"
//                           />
//                         </div>
//                         <div class="mb-2">
//                           <input
//                             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                             id="mobile"
//                             type="text"
//                             placeholder="Your Mobile Number"
//                           />
//                         </div>
//                         <div class="mb-2">
//                           <input
//                             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                             id="email"
//                             type="email"
//                             placeholder="Your Email"
//                           />
//                         </div>
//                         {/* <div class="mb-2">
//                           <select
//                             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                             id="course"
//                           >
//                             <option value="">Choose a Course</option>
//                             <option value="abroad">Abroad</option>
//                             <option value="india">India</option>
//                           </select>
//                         </div> */}
//                         <div class="mb-2">
//                           <input
//                             class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//                             id="name"
//                             type="text"
//                             placeholder="Enter Your Location"
//                           />
//                         </div>
//                         <button
//                           class="bg-red-500 hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//                           type="button"
//                         >
//                           Talk to Experts
//                         </button>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <br></br>
//               <br></br>
//               <br></br>
//               <br></br>
//               <br></br>
//             </div>
//           </div>
//         </div>
//         <div id="particles-1" className="particles"></div>
//       </div>
//     </header>
//   );
// }

// export default Header1;

import React, { useState, useEffect } from "react";
import "./navbar.css";

function Header1() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);


  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen); // Toggle the state directly
  };
  
  // const toggleNavbar = () => {
  //   setIsNavbarOpen((prevState) => !prevState);
  // };

  useEffect(() => {
    const handleScroll = () => {
      // Your scroll handling code here
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const navbarArea = document.querySelector(".navbar-area");
      const navbarBrandImg = document.querySelector(
        ".navbar .navbar-brand img"
      );
      if (scroll < 20) {
        navbarArea.classList.remove("sticky");
        navbarBrandImg.src = "/assets/svg/logo.svg";
      } else {
        navbarArea.classList.add("sticky");
        navbarBrandImg.src = "/assets/svg/logo.svg";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header-area">
      <div className="navbar-area ">
        <div className="container relative">
          <div className="row">
            <div className="w-full">
              <nav className="flex items-center justify-between navbar navbar-expand-lg ">
                <a className="mr-4 navbar-brand" href="/">
                  <img className="w-40" src="assets/svg/logo.svg" alt="Logo" />
                  {/* <img
                  
                    src="/assets/images/final_logo.png"
                    alt="Logo"
                    className="navbar-logo"
                  /> */}
                </a>
                <button
                  className="block navbar-toggler focus:outline-none lg:hidden "
                  type="button"
                  onClick={toggleNavbar}
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                <div
                  className={`absolute left-28 z-20 w-full px-5 duration-300 bg-slate-50 shadow-lg lg:w-auto navbar-collapse lg:block top-full mt-full lg:static lg:bg-transparent lg:shadow-none 
                    ${isNavbarOpen ? "block" : "hidden"} rounded-lg border-red-900`}
                  id="navbarOne"
                >
                  <ul
                    id="nav"
                    className="items-center content-start mr-auto lg:justify-center navbar-nav lg:flex"
                  >
                    <li className="nav-item ">
                      <a
                        className="page-scroll"
                        href="#home"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#features"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Universities
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#about"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Colleges
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#facts"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Courses
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#team"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Exams
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#blog"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="page-scroll"
                        href="#blog"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Login
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div
        id="home"
        className="header-hero "
        // style={{ backgroundImage: "url(/assets/images/slider.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="w-full lg:w-1/2">
              <div className="pt-96 mb-8 text-center lg:pt-48 header-hero-content sm:pt-80 ">
                {/* Content */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>

            <div className="w-full lg:w-1/3 ml-auto">
              {/* <div className="pt-8 header-hero-image">Image</div> */}

              {/* Form */}
              <div className="py-2 sm:py-8 lg:py-6">
                <div className="mx-auto max-w-screen-sm px-4 md:px-8">
                  <div className="mb-50 md:mb-16">
                    <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                      <h2 class="text-red-500 text-lg font-bold mb-4 text-center">
                        APPLY NOW
                      </h2>

                      <p>Your next opportunity awaits!</p>
                      <div className="my-2 flex items-center">
                        <div className="flex-1 narrowline  bg-black border-t"></div>
                        <div className="mx-1 text-black">
                          Select the Course Country
                        </div>
                        <div className="flex-1 narrowline  bg-black border-t"></div>
                      </div>
                      {/* <hr class="my-2" /> */}

                      <div class="grid grid-cols-2 gap-4">
                        <button class="bg-white hover:bg-red-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                          Abroad
                        </button>
                        <button class="bg-white hover:bg-red-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                          India
                        </button>
                      </div>
                      {/* <hr className="my-4 border-b border-gray-400" /> */}
                      {/* <h4 className="text-black my-2">Enter Your Details</h4> */}
                      <div className="my-2 flex items-center">
                        <div className="flex-1 narrowline  bg-black border-t"></div>
                        <div className="mx-4 text-black">
                          Enter Your Details
                        </div>
                        <div className="flex-1 narrowline  bg-black border-t"></div>
                      </div>

                      <div class="mb-2">
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="name"
                          type="text"
                          placeholder="Your Name"
                        />
                      </div>
                      <div class="mb-2">
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="mobile"
                          type="text"
                          placeholder="Your Mobile Number"
                        />
                      </div>
                      <div class="mb-2">
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="email"
                          type="email"
                          placeholder="Your Email"
                        />
                      </div>
                      <div class="mb-2">
                        <select
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="course"
                        >
                          <option value="">Choose a Course</option>
                          <option value="abroad">Btech</option>
                          <option value="india">M.Tech</option>
                        </select>
                      </div>
                      <div class="mb-2">
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="name"
                          type="text"
                          placeholder="Enter Your Location"
                        />
                      </div>
                      <button
                        class="bg-red-500 hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                      >
                        Talk to Experts
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <br></br>
            </div>
          </div>
        </div>
        {/* <div id="particles-1" className="particles"></div> */}
      </div>
    </header>
  );
}

export default Header1;
