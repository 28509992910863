import React from "react";
import './topcollege.css'
const FeatureSection = () => {
  return (
    <section className="text-gray-600 body-font overflow-hidden  rounded-lg p-5 mb-7">
      <div className="container   px-5 py-5 mx-auto">
        <div className="flex flex-wrap bg-white p-5 rounded-lg mb-14">
          {/* Service Section */}
          <div className="flex flex-col md:flex-row w-full">
            <div className="md:w-1/2 flex flex-col items-start">
              <div className="w-full lg:w-1">
                <div className="pb-5 text-center section-title">
                  <h3 className="title text-red-500">Our Services</h3>
                </div>
              </div>
              <div className="sm:text-xl text-lg title-font font-medium text-gray-900 mt-4 mb-4">
                <ul className="list-group p-5">
                  <li className="list-group-item flex items-center">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Educational Counselling to Students
                  </li>
                  <li className="list-group-item flex items-center">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Direct Meeting with College Authorities
                  </li>
                  <li className="list-group-item flex items-center">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Pre and Post-Admission Care
                  </li>
                  <li className="list-group-item flex items-center">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Seat Reservation Facilities
                  </li>
                  <li className="list-group-item flex items-start">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Assistance to Secure Educational Loans and Scholarships
                  </li>
                  <li className="list-group-item flex items-center">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Guidance to Pay Fees in Easy EMIs
                  </li>
                </ul>
              </div>
              {/* <div className="sm:text-3xl text-xl title-font font-medium text-gray-900 mt-4 mb-4">
                <ul className="list-group">
                  <li className="list-group-item">
                     <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    /> Educational Counselling to Students
                  </li>
                  <li className="list-group-item">
                     <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    /> Direct Meeting with College Authorities
                  </li>
                  <li className="list-group-item">
                     <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    /> Pre and Post-Admission Care
                  </li>
                  <li className="list-group-item">
                    <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />
                    Seat Reservation Facilities
                  </li>

                  <li className="list-group-item">
                     <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    />Assistance to Secure Educational Loans <br></br> &nbsp;&nbsp;&nbsp;&nbsp;   and Scholarships
                  </li>
                  <li className="list-group-item">
                     <img
                      src="assets/images/right tick.png"
                      alt="Icon"
                      className="inline-block w-4 h-4 mr-2"
                    /> Guidance to Pay Fees in Easy EMIs
                  </li>
                </ul>
              </div> */}
              {/* <div className="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full"></div> */}
            </div>
            <div className="inline-block h-[250px] min-h-[15em] ww-1 self-stretch bg-red-300 opacity-100 dark:opacity-50 pt-8 mt-28 hide-on-mobile"></div>
     
            {/* Why Choose Us Section */}
            <div className="md:w-1/2 flex flex-col items-center p-8">
              <div className="pb-1 text-center section-title">
                <h3 className="title text-red-500">Why Choose Us</h3>
              </div>
              <p className="sm:text-3xl text-xl md:text-4xl title-font font-medium text-gray-900 mt-1 mb-4 px-5">
  Onestopadmission.com is one of the fastest-growing Educational Service Providers. We empower top-quality education with pioneering services. We have successfully guided 40,000+ students to pursue their desired courses at top colleges. So, what are you waiting for? Reach out to us now!
</p>

              {/* <div className="flex items-center flex-wrap pb-4 mb-4 border-b-2 border-gray-100 mt-auto w-full"></div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
