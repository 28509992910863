import React from "react";
import './college.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = () => {
  const PrevButton = ({ onClick }) => (
    <button className="text-lg px-3 py-2 rounded" style={{ color: "red", fontSize: "30px", position: "absolute", left: "1px", top: "45%", transform: "translateY(-50%)", zIndex: "10" }} onClick={onClick}>
      {/* Left icon */}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-2/3 w-10">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </button>
  );
  
  const NextButton = ({ onClick }) => (
    <button className="text-lg px-3 py-2 rounded" style={{ color: "red", fontSize: "20px", position: "absolute", right: "1px", top: "45%", transform: "translateY(-50%)", zIndex: "10" }} onClick={onClick}>
      {/* Right icon */}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-2/3 w-10">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  };

  return (
    <div className="pb-5 text-center section-title mt-5">
      <h3 className="title text-red-500">Top Colleges </h3>
      <Slider {...settings}>
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i}>
            <section id="features" className="services-area">
              <div className="container pb-5">
                <div className="w-full lg:w-1"></div>
                <div className="flex flex-wrap justify-center">
                  {/* Card 1 */}
                  <div className="w-full sm:w-2/3 lg:w-1/3">
                    <div className="single-services wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/img2.png" alt="shape" />
                        <i className="lni lni-balloon"></i>
                      </div>
                      <div className="mt-2 mb-8 services-content">
                        <h4 className="mb-4 text-l font-bold text-blue-300">ST. JOSEPH'S COLLEGE OF COMMERCE - SJCC (AUTONOMOUS)</h4>
                        <a href="/" className="text-white bg-red-500 py-2 px-3 text-sm rounded">Apply Now</a>
                        <a href="/" className="text-red-500 bg-white py-2 px-3 border border-red-500 text-sm rounded ml-1">View Details</a>
                      </div>
                    </div>
                  </div>
                  {/* Card 2 */}
                  <div className="w-full sm:w-2/3 lg:w-1/3">
                    <div className="mt-8 text-center single-services wow fadeIn">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/img1.png" alt="shape" />
                        <i className="lni lni-cog"></i>
                      </div>
                      <div className="mt-2 mb-8 services-content">
                        <h4 className="mb-4 text-l font-bold text-blue-300">M.S RAMAIAH INSTITUTE OF TECHNOLOGY ( MSRIT )</h4>
                        <a href="/" className="text-white bg-red-500 py-2 px-3 text-sm rounded">Apply Now</a>
                        <a href="/" className="text-red-500 bg-white py-2 px-3 border border-red-500 text-sm rounded ml-1">View Details</a>
                      </div>
                    </div>
                  </div>
                  {/* Card 3 */}
                  <div className="w-full sm:w-2/3 lg:w-1/3">
                    <div className="mt-8 text-center single-services wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                      <div className="services-icon">
                        <img className="shape" src="assets/images/img3.png" alt="shape" />
                        <i className="lni lni-bolt-alt"></i>
                      </div>
                      <div className="mt-2 mb-8 services-content">
                        <h4 className="mb-4 text-l font-bold text-blue-300">SCHOOL OF COMMERCE, JAIN (DEEMED-TO-BE) UNIVERSITY</h4>
                        <a href="/" className="text-white bg-red-500 py-2 px-3 text-sm rounded">Apply Now</a>
                        <a href="/" className="text-red-500 bg-white py-2 px-3 border border-red-500 text-sm rounded ml-1">View Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
