








import React from "react";
import "./footer.css";
function Footer() {
  return (
    <footer
      id="footer"
      className="relative z-10 footer-area mt-10 pt-12 pb-6 bg-gradient-to-b from-red-500 to-black text-white"
      style={{
        backgroundImage: "url('assets/images/footerbg.svg')", // Specify the path to your image
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="container mx-auto">
        {/* Subscribe Section */}
        <div
          className="px-1 pt-6 pb-8 mb-1 z-30 bg-red-200 rounded-lg shadow-xl md:px-8 subscribe-area wow fadeIn "
          data-wow-duration="1s"
          data-wow-delay="0.5s"
          style={{
            // width:"90%",
            background: "linear-gradient(to left, #fd1d1d,#100208)",
            position: "relative",
            top: "50%", // Adjust this value as needed
            transform: "translateY(-50%)",
          }}
        >
          <div className="row items-center">
            <div className="w-full lg:w-1/2">
              <div className="lg:mt-2 subscribe-content text-center">
                <h2 className="text-2xl font-bold sm:text-4xl subscribe-title text-white flex items-center justify-center">
                  <img
                    className="shape h-14"
                    src="assets/svg/email subscribe icon-06.svg"
                    alt="shape"
                  />
                  <span className="ml-2">Subscribe for</span>
                  <br className="sm:hidden" />
                  <span className="ml-2">Newsletter</span>
                </h2>
                <span className="block font-light sm:text-sm mx-4">
                  Get Latest Update & Trends
                </span>
              </div>
            </div>

            <div className="relative flex w-full max-w-[24rem] justify-between items-center">
              <div className="relative h-10 w-full min-w-[200px]">
                <input
                  type="email"
                  className="peer h-full w-full rounded-full border border-blue-gray-200 border-t-transparent bg-white px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder="Your Email "
                  value=""
                />
              </div>
              <button
                disabled
                className="!absolute right-1 top-1 select-none rounded-full bg-red-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-100 disabled:shadow-none"
                type="button"
              >
                Start Now &nbsp;&nbsp;&nbsp;
                <img
                  src="assets/images/start now icon.png"
                  alt="Icon"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 rounded-full bg-white p-1 shadow-lg"
                />
              </button>
            </div>
          </div>
        </div>

        {/* Footer Widgets */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Company */}
          <div className="footer-widget ">
            <h4 className="text-xl font-bold mb-4">Company</h4>
            <ul className="text-lg">
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Road Map</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Privacy Policy</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Refund Policy</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Terms of Service</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Pricing</a>
              </li>
            </ul>
          </div>

          {/* Top Colleges */}
          <div className="footer-widget ">
            <h4 className="text-xl font-bold mb-4">Top Colleges</h4>
            <ul className="text-lg">
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Road Map</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Privacy Policy</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Refund Policy</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Terms of Service</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Pricing</a>
              </li>
            </ul>
          </div>

          {/* Top Universities */}
          <div className="footer-widget ">
            <h4 className="text-xl font-bold mb-4">Top Universities</h4>
            <ul className="text-lg">
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Home</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Page</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Portfolio</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Blog</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">Contact</a>
              </li>
            </ul>
          </div>

          {/* Trending Courses */}
          <div className="footer-widget ">
            <h4 className="text-xl font-bold mb-4">Trending Courses</h4>
            <ul className="text-lg">
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">B.Tech</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">M.Tech</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">B.C.A</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">M.C.A</a>
              </li>
              <li>
                <img
                  src="assets/images/tick.png"
                  alt="Icon"
                  className="inline-block w-4 h-4"
                />{" "}
                <a href="/">L.L.B</a>
              </li>
            </ul>
          </div>
        </div>

        <br></br>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center mt-5">
          <div class="flex flex-col items-center">
          <img
              className="shape h-8"
              src="assets/svg/header location-05.svg"
              alt="shape"
            />
            #147 Hennur, Main Road,
            <br /> Kannur, South Bangalore,
            <br /> Karnataka – 562149
            <br /> <span className="text-yellow-300">Our Address</span>
          </div>
          <div class="flex flex-col items-center">
          <img
              className="shape h-7"
              src="assets/svg/footer email icon-06.svg"
              alt="shape"
            />
            info@onestopadmission.com <br />
            <span className="text-yellow-300">Our Mailbox</span>
          </div>
          <div class="flex flex-col items-center">
            <img
              className="shape h-7"
              src="assets/svg/header call-05-05.svg"
              alt="shape"
            />
            +91 9353197347 <br />
            <span className="text-yellow-300">Our Phone</span>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div class="..."></div>
          <div class="... mt-16">
            <div className="flex justify-center items-center rounded-full p-1 m-3 space-x-2">
              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4  text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </button>
              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                </svg>
              </button>
              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                </svg>
              </button>

              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>

              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </button>
              <button className="rounded-full bg-white p-1 shadow-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </button>
            </div>
          </div>
          <div class="...">
            <div class="flex justify-center items-center">
              <img
                className="shape w-25 h-15 md:w-32 md:h-20 lg:w-40 lg:h-20 xl:w-52 xl:h-32"
                src="assets/svg/footer logo white-07.svg"
                alt="shape"
              />
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div class="flex flex-col md:flex-row justify-between items-center border-t border-gray-300 py-4 mt-8 text-white">
  <p class="mb-4 md:mb-0 border-blue-800 md:border-none md:mr-8">
    Disclaimer | Privacy Policy | Terms & Services | Cookies Policy | Sitemap
  </p>
  <p>
    <a href="#" class="text-white hover:text-red-600">
      &copy; 2024, One Stop Admission. All right reserved.
    </a>
  </p>
</div>

      </div>
    </footer>
  );
}

export default Footer;
