import React from "react";

<style></style>;

function Header() {
  return (
    <div className="topbar bg-blue-700 flex flex-wrap flex-col md:flex-row items-center text-white mx-auto">
    <nav className="md:mr-auto md:py-1 md:pl-1 md:border-gray-400 flex flex-wrap text-base justify-center">
      <a
        href="#"
        className="ml-12 transition-colors duration-500 hover:text-gray-900"
      >
        About Us
      </a>
  
      <a
        href="#"
        className="ml-2  transition-colors duration-500 hover:text-gray-950 md:border-l-1 pl-2"
      >
        Admission
      </a>
      <a
        href="#"
        className="ml-2  transition-colors duration-500 hover:text-gray-950 md:border-l-1 pl-2"
      >
        Contact Us
      </a>
    </nav>
    <div className="flex space-x-4">
      {/* Map Button */}
      <button className="flex mr-5 items-center border-0 py-1 px-1 focus:outline-none  transition-colors duration-500 hover:text-gray-950 rounded text-base">
        <img
          className="shape-1 w-5 "
          src="assets/svg/header location-05.svg"
          alt="shape"
        />
       &nbsp; &nbsp; Bangalore
      </button>
  
      {/* Contact Button */}
      <button className="flex ml-8 items-center border-0 py-1 px-1 focus:outline-none  transition-colors duration-500 hover:text-gray-950 rounded text-base"style={{
        marginRight:"70px"
      }}>
  <img
    className="shape-1 w-5 mr-"
    src="assets/svg/header call-05-05.svg"
    alt="shape"
  />
  &nbsp;
  +91 9353197347
</button>

    </div>
  </div>
  
  );
}

export default Header;
