import React from "react";
import "./content.css";
function Features() {
  return (
    <section id="features" className="services-area mb-5 ">
      <div className="container ">
        {/* row */}
        <div className="justify-center row single-servicess">
          <div className="w-full sm:w-11/12 lg:w-1/3 mb-8 sm:mb-0">
            <div
              className="single-services wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="services-icon" style={{ position: "relative" }}>
                <img
                  className="shape h-40"
                  src="assets/svg/collage icon-02.svg"
                  alt="shape"
                />
                <div className="number-overlay">120</div>
              </div>
              <div className="mt-5 services-content">
                <h4 className="mb-2 text-xl font-bold text-red-400">
                  Colleges
                </h4>
                <p className="mb-8">
                  Get complete information from the list of Courses to Amenities
                  of various Colleges in Karnataka. Find the college of your
                  choice from over 900+ colleges.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-11/12 lg:w-1/3 mb-8 sm:mb-0">
            <div
              className="mt-2 text-center single-services wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <div className="services-icon" style={{ position: "relative" }}>
                <img
                  className="shape h-40"
                  src="assets/svg/university icon-02.svg"
                  alt="shape"
                />
                <div className="number-overlay">80</div>
              </div>

              <div className="mt-1 services-content">
                <h4 className="mb-3 text-xl font-bold text-red-400">
                  Universities
                </h4>
                <p className="mb-8">
                  Explore all the Central, State and Private Universities of
                  Karnataka to know more about courses offered, scholarships and
                  other information.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-11/12 lg:w-1/3 ">
            <div
              className="mt-8 text-center single-services wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.8s"
            >
              <div className="services-icon" style={{ position: "relative" }}>
                <img
                  className="shape h-40"
                  src="assets/svg/marks icon-02.svg"
                  alt="shape"
                />
                <div className="number-overlay">180</div>
              </div>

              <div className="mt-1 services-content">
                <h4 className="mb-2 text-xl font-bold text-red-400">Exams</h4>
                <p className="mb-2">
                  Find all the latest information about different entrance
                  exams. Search for any national level or state level entrance
                  exams to know the dates, syllabus, eligibility criteria and
                  more.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
}

export default Features;
