import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = () => {
  const PrevButton = ({ onClick }) => (
    <button className="text-lg px-3 py-2 rounded" style={{ color: "#fff", fontSize: "30px", position: "absolute", left: "30px", top: "45%", transform: "translateY(-50%)", zIndex: "10" }} onClick={onClick}>
      {/* Left icon */}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-2/3 w-10">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </button>
  );
  
  const NextButton = ({ onClick }) => (
    <button className="text-lg px-3 py-2 rounded" style={{ color: "#fff", fontSize: "20px", position: "absolute", right: "30px", top: "45%", transform: "translateY(-50%)", zIndex: "10" }} onClick={onClick}>
      {/* Right icon */}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-2/3 w-10">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </button>
  );
  
 
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Adjusted speed for smoother transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  };

  return (
    <div className="pb-1 text-center section-title pt-5" style={{ backgroundColor: "#6585c9" }}>
      <h3 className="title text-white mt-5"> Top Trending Courses </h3>
    
      <Slider {...settings}>
        {Array.from({ length: 3 }).map((_, i) => (
          <div key={i}>
            <div className="container px-4 mx-auto dark:bg-gray-500" style={{ backgroundColor: "#6585c9" }}>
              <div className="w-full lg:w-1">
                {/* Content Goes Here */}
              </div>

              <div className="flex justify-center">
                <div className="container mt-5 p-4 mx-auto dark:bg-gray-500" style={{ backgroundColor: "#6585c9", borderRadius: "20px", maxWidth: "1070px" }}>
                  <div className="flex flex-wrap my-12 dark:text-white-700 bg-white" style={{ borderRadius: "20px", marginTop: "5px" }}>
                    <div className="w-full p-6 md:w-1/2 lg:w-1/3">
                      <div className="flex items-center">
                        <img className="shape-1 h-20" src="assets/svg/medical icon-02.svg" alt="shape" />
                        <div className="ml- text-lg font-bold" style={{ color: "#6585c9", fontSize: "1.5rem" }}>Medical</div>
                      </div>
                    </div>
                    <div className="w-full p-6 md:w-1/2 lg:w-1/3">
                      <div className="flex items-center mb-4">
                        <img className="shape-1 h-20" src="assets/svg/Dental icon-02.svg" alt="shape" />
                        <div className="ml-4 font-bold text-lg" style={{ color: "#6585c9", fontSize: "1.5rem" }}>Dental</div>
                      </div>
                    </div>
                    <div className="w-full p-6 md:w-1/2 lg:w-1/3 lg:border-r-0">
                      <div className="flex items-center mb-4">
                        <img className="shape-1 h-20" src="assets/svg/Naturopathy icon-02.svg" alt="shape" />
                        <div className="text-lg font-bold" style={{ color: "#6585c9", fontSize: "1.5rem" }}>Naturopathy and Yogic Science</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
