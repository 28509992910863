
import './App.css';
// import './swiper.min.css'
import CardSlider from './component/college';



import YourComponent from './component/course';
import Footer from './component/footer/footer';


import TestimonialsSection from './component/testinomial/testinomial';
import FeatureSection from './component/topcollege/topcollege';
import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import University from './component/university';
import Exam from './component/exam';
import Header1 from './component/navbar/navbar';
import Test, { CarouselDefault } from './component/test';
import Features from './component/content/content';
import Header from './component/navbar/header';

function App() {
  return (
    <div className="App">
      {/* <CarouselDefault></CarouselDefault> */}
      <Header></Header>
      <div > 
      <Header1></Header1>
      </div>
     
      
      <Features></Features>
      <YourComponent></YourComponent>
      <CardSlider></CardSlider>
      <University></University>
      <Exam></Exam>

      {/* <Test></Test> */}
      {/* <CarouselWithContent></CarouselWithContent> */}
      {/* <FeatureSection></FeatureSection> */}
      <TestimonialsSection></TestimonialsSection>
      <Footer></Footer>
    </div>
  );
}

export default App;
