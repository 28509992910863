// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

// const TestimonialsSection = () => {
//   const testimonials = [
//     {
//       content:
//         "Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.",
//       author: "Holden Caulfield",
//       role: "UI DEVELOPER",
//       imageUrl: "https://dummyimage.com/80x80",
//     },
//     {
//       content:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       author: "Jane Doe",
//       role: "GRAPHIC DESIGNER",
//       imageUrl: "https://dummyimage.com/80x80",
//     },
//     // Add more testimonials as needed
//   ];

//   return (
//     <section className="text-gray-600 body-font">
//       <div className="container px-4 py-10 mx-auto">
//         <Carousel
//           additionalTransfrom={0}
//           arrows
//           autoPlaySpeed={5000}
//           centerMode={false}
//           containerClass="container"
//           draggable
//           focusOnSelect={false}
//           infinite
//           keyBoardControl
//           minimumTouchDrag={80}
//           renderButtonGroupOutside={false}
//           renderDotsOutside={false}
//           responsive={{
//             desktop: {
//               breakpoint: {
//                 max: 5000,
//                 min: 1024,
//               },
//               items: 2,
//               partialVisibilityGutter: 40,
//             },
//             mobile: {
//               breakpoint: {
//                 max: 464,
//                 min: 0,
//               },
//               items: 1,
//               partialVisibilityGutter: 30,
//             },
//           }}
//           showDots
//           slidesToSlide={1}
//           swipeable
//         >
//           {testimonials.map((testimonial, index) => (
//             <div key={index} className="md:w-1/2 w-full mb-8 md:mb-0 px-4 container relative">
//               <div
//                 className="container md:w-full w-full md:mb-0 mx-auto p-1 rounded-lg relative"
//                 style={{
//                   backgroundColor: "#6585c9",
//                   backgroundImage: "url('assets/svg/say-09.svg')",
//                  
//                  
//                 }}
//               >
//                 <img
//                   src="/assets/images/star.png"
//                   alt="star"
//                   className="absolute top-0 right-0 mt-14 mr-8 w-25 h-10"
//                 />
//                 <div className="h-auto md:min-h-16 p-1 rounded">
//                   <div className="p-6 rounded-2xl text-center">
//                     <p className="leading-relaxed mb-4 md:mb-8 text-center text-sm md:text-base">
//                       {testimonial.content}
//                     </p>
//                     <div className="flex items-center justify-center">
//                       <img
//                         alt="testimonial"
//                         src={testimonial.imageUrl}
//                         className="w-10 h-10 rounded-full flex-shrink-0 object-cover object-center"
//                       />
//                       <div className="flex-grow flex flex-col pr-36">
//                         <span className="title-font font-medium text-gray-900">
//                           {testimonial.author}
//                         </span>
//                         <span className="text-gray-500 text-sm">{testimonial.role}</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </Carousel>
//       </div>
//     </section>
//   );
// };

// export default TestimonialsSection;



// import React from "react";

// const TestimonialsSection = () => {
//   return (
//     <section className="text-gray-600 body-font">
//       <div className="container px-10 py-28 mx-auto max-w-4xl shadow-xl relative">
//         {/* Star Image */}
        
//         <div className="flex flex-wrap -m-4">
//           <div className="p-6 md:w-1/2 w-full relative">
//             <div className="shadow-lg rounded-lg p-14 pl-7 pr-5 relative" style={{ backgroundColor: "#6484c8", width: "100%" }}>
//               <img
//                 src="assets/svg/1.png"
//                 alt="Icon"
//                 className="absolute -top-8 left-7 mt-12 -ml-1 w-24 h-24"
//               />
//               <img
//                   src="/assets/images/star.png"
//                   alt="star"
//                   className="absolute top-8 right-5 mt-14 mr-8 w-25 h-10"
//                 />
//               <img
//                 src="assets/svg/2.png"
//                 alt="Icon"
//                 className="absolute bottom-0 right-7 mb-5 -mr-3 w-24 h-24"
//               />
//               <div className="bg-white p-10 rounded-lg">
//                 <p className="leading-relaxed mb-12 mt-12">
//                   Synth chartreuse iPhone lomo cray raw denim brunch everyday
//                   carry neutra before they sold out fixie 90's microdosing. Tacos
//                   pinterest fanny pack venmo, post-ironic heirloom try-hard pabst
//                   authentic iceland.
//                 </p>
//                 <a className="inline-flex items-center">
//                   <img
//                     alt="testimonial"
//                     src="/assets/svg/user.png"
//                     className="w-14 h-14 rounded-full flex-shrink-0 object-cover object-center"
//                   />
//                   <span className="flex-grow flex flex-col pl-4">
//                     <span className="title-font font-medium text-gray-900">
//                       Holden Caulfield
//                     </span>
//                     <span className="text-gray-500 text-sm">UI DEVELOPER</span>
//                   </span>
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="p-6 md:w-1/2 w-full relative">
//             <div className="shadow-lg rounded-lg p-14 pl-7 pr-5 relative" style={{ backgroundColor: "#6484c8", width: "100%" }}>
//               <img
//                 src="assets/svg/1.png"
//                 alt="Icon"
//                 className="absolute -top-8 left-7 mt-12 -ml-1 w-24 h-24"
//               />
//                <img
//                   src="/assets/images/star.png"
//                   alt="star"
//                   className="absolute top-8 right-5 mt-14 mr-8 w-25 h-10"
//                 />
//               <img
//                 src="assets/svg/2.png"
//                 alt="Icon"
//                 className="absolute bottom-0 right-7 mb-5 -mr-3 w-24 h-24"
//               />
//               <div className="bg-white p-10 rounded-lg">
//                 <p className="leading-relaxed mb-12 mt-12">
//                   Synth chartreuse iPhone lomo cray raw denim brunch everyday
//                   carry neutra before they sold out fixie 90's microdosing. Tacos
//                   pinterest fanny pack venmo, post-ironic heirloom try-hard pabst
//                   authentic iceland.
//                 </p>
//                 <a className="inline-flex items-center">
//                   <img
//                     alt="testimonial"
//                     src="/assets/svg/user.png"
//                     className="w-14 h-14 rounded-full flex-shrink-0 object-cover object-center"
//                   />
//                   <span className="flex-grow flex flex-col pl-4">
//                     <span className="title-font font-medium text-gray-900">
//                       Holden Caulfield
//                     </span>
//                     <span className="text-gray-500 text-sm">UI DEVELOPER</span>
//                   </span>
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TestimonialsSection;


import React from "react";
import Slider from "react-slick";
import './testinomial.css'

const TestimonialsSection = () => {
  const PrevButton = ({ onClick }) => (
    <button
      className="text-lg px-3 py-2 rounded prev-button"
      style={{
        color: "#00339f",
        fontSize: "30px",
        position: "absolute",
        left: "1px",
        top: "45%",
        transform: "translateY(-50%)",
        zIndex: "10",
      }}
      onClick={onClick}
    >
      {/* Left icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-2/3 w-10"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
    </button>
  );

  const NextButton = ({ onClick }) => (
    <button
      className="text-lg px-3 py-2 rounded next-button"
      style={{
        color: "#00339f",
        fontSize: "20px",
        position: "absolute",
        right: "1px",
        top: "45%",
        transform: "translateY(-50%)",
        zIndex: "10",
      }}
      onClick={onClick}
    >
      {/* Right icon */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-2/3 w-10"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500, // Adjusted speed for smoother transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  };

  return (
    <Slider {...settings}>
      {Array.from({ length: 3 }).map((_, i) => (
        <section key={i} className="text-gray-600 body-font">
          <div className="container px-10 py-28 mx-auto max-w-4xl shadow-xl relative">
            {/* Star Image */}
            <div className="flex flex-wrap -m-4">
              <div className="p-6 md:w-1/2 w-full relative">
                <div
                  className="shadow-lg rounded-lg p-14 pl-7 pr-5 relative"
                  style={{ backgroundColor: "#6484c8", width: "100%" }}
                >
                  <img
                    src="assets/svg/1.png"
                    alt="Icon"
                    className="absolute -top-8 left-7 mt-12 -ml-1 w-24 h-24"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="absolute top-8 right-5 mt-14 mr-8 w-25 h-10"
                  />
                  <img
                    src="assets/svg/2.png"
                    alt="Icon"
                    className="absolute bottom-0 right-7 mb-5 -mr-3 w-24 h-24"
                  />
                  <div className="bg-white p-10 rounded-lg">
                    <p className="leading-relaxed mb-12 mt-12">
                      Synth chartreuse iPhone lomo cray raw denim brunch everyday
                      carry neutra before they sold out fixie 90's microdosing. Tacos
                      pinterest fanny pack venmo, post-ironic heirloom try-hard pabst
                      authentic iceland.
                    </p>
                    <a className="inline-flex items-center">
                      <img
                        alt="testimonial"
                        src="/assets/svg/user.png"
                        className="w-14 h-14 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-gray-900">
                          Holden Caulfield
                        </span>
                        <span className="text-gray-500 text-sm">UI DEVELOPER</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="p-6 md:w-1/2 w-full relative hide-on-mobile">
                <div className="shadow-lg rounded-lg p-14 pl-7 pr-5 relative" style={{ backgroundColor: "#6484c8", width: "100%" }}>
                  <img
                    src="assets/svg/1.png"
                    alt="Icon"
                    className="absolute -top-8 left-7 mt-12 -ml-1 w-24 h-24"
                  />
                   <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="absolute top-8 right-5 mt-14 mr-8 w-25 h-10"
                    />
                  <img
                    src="assets/svg/2.png"
                    alt="Icon"
                    className="absolute bottom-0 right-7 mb-5 -mr-3 w-24 h-24"
                  />
                  <div className="bg-white p-10 rounded-lg">
                    <p className="leading-relaxed mb-12 mt-12">
                      Synth chartreuse iPhone lomo cray raw denim brunch everyday
                      carry neutra before they sold out fixie 90's microdosing. Tacos
                      pinterest fanny pack venmo, post-ironic heirloom try-hard pabst
                      authentic iceland.
                    </p>
                    <a className="inline-flex items-center">
                      <img
                        alt="testimonial"
                        src="/assets/svg/user.png"
                        className="w-14 h-14 rounded-full flex-shrink-0 object-cover object-center"
                      />
                      <span className="flex-grow flex flex-col pl-4">
                        <span className="title-font font-medium text-gray-900">
                          Holden Caulfield
                        </span>
                        <span className="text-gray-500 text-sm">UI DEVELOPER</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}
    </Slider>
  );
};

export default TestimonialsSection;
